import { isArray, isObject } from "lodash";
import Axios from "axios";

import api from "../../apiSingleton";
import { API_PREFIX, API_URL } from '../../config';

import {
  UPDATE_SETTINGS_DATA,
  UPDATE_SETTINGS_CITIES_LIST,
  UPDATE_SETTINGS_KITCHENS_LIST,
  UPDATE_SETTINGS_LOCATIONS_LIST,
  UPDATE_SETTINGS_ORDER_STATUSES,
  UPDATE_SETTINGS_ORDER_TYPES,
  UPDATE_SETTINGS_ITEMS_STATUSES,
  UPDATE_SETTINGS_PRODUCT_CATEGORIES,
  UPDATE_SETTINGS_PRODUCT_TYPES,
  UPDATE_SETTINGS_ROLES_LIST,
  UPDATE_KITCHEN_FORM,
  UPDATE_KITCHEN_FORM_SHIFT_FIRST,
  UPDATE_KITCHEN_FORM_SHIFT_LAST,
  SET_KITCHEN,
  UPDATE_SETTINGS_RESTAURANS_LIST,
  UPDATE_RESTAURANT_FORM,
  UPDATE_CITY_FORM,
  CLEANING_NEW_FORM,
  UPDATE_LOCATION_FORM,
  GET_LOCATION,
  GET_CITY,
  SET_RESTORANT,
  UPDATE_ROLE_FORM,
  GET_ROLE,
  UPDATE_ROLE_FORM_PAGES,
  UPDATE_FORM_PROMOTION,
  UPDATE_FORM_PROMOTION_WEEK,
  UPDATE_FORM_PROMOTION_WINDOW,
  GET_PROMOTION,
  GET_PROMOTIO_ITEM,
  GET_PROMOCODE,
  UPDATE_FORM_PROMOCODE_WINDOW,
  UPDATE_FORM_PROMOCODE,
  GET_PROMOCODE_ITEM,
  UPDATE_FORM_PROMOCODE_ARRAY,
  UPDATE_FORM_RESOURCE,
  GET_RESOURCE_LIST,
  GET_RESOURCE_ID,
  GET_PAYMENT_LIST,
  UPDATE_PAYMENT_FORM,
  GET_PAYMENT_ID,
  DELETE_ONE_FIELD_PROMOCODE,
  ADD_ONE_FIELD_PROMOCODE,
  UPDATE_ONE_FIELD_CODE_PROMOCODE,
  ADD_ONE_FIELD_CODE_PROMOCODE,
  DELETE_ONE_FIELD_CODE_PROMOCODE,
  UPDATE_FORM_PICKUP,
  GET_FORM_PICKUP,
  DELETE_CITY_BY_ID,
  UPDATE_CITY_STREET_BY_ID,
  ADD_STREET_BY_CITY,
  UPDATE_FORM_TYPE_OF_DELIVERY,
  GET_TYPES_OF_DELIVERY_LIST,
  GET_DELIVERY_ID,
  UPDATE_FORM_REASON,
  GET_REASONS_LIST,
  GET_REASON_ID,
  CLEANING_NEW_FORM_OBJ,

  GET_BUG_REPORT_ALL,
  UPDATE_BUG_REPORT_LOAD_PAGE,
  UPDATE_BUG_REPORT_LOAD_MODAL,
  SELECT_BUG_REPORT_TO_VIEW,

  GET_COMPLAINT_ALL,
  UPDATE_COMPLAINT_LOAD_PAGE,
  UPDATE_COMPLAINT_LOAD_MODAL,
  GET_COMPLAINT_ONE,
  UPDATE_COMPLAINT_FORM,
  COMPLAINT_FORM_CLEAN,
  GET_COMPLAINT_ALL_SOURCES,
  UPDATE_COMPLAINT_LOAD_PAGE_SOURCES,
  UPDATE_COMPLAINT_LOAD_MODAL_SOURCES,
  GET_COMPLAINT_ONE_SOURCES,
  UPDATE_COMPLAINT_FORM_SOURCES,
  COMPLAINT_FORM_CLEAN_SOURCES,

  UPDATE_SETTINGS_PRODUCT_CATEGORIES_FORM,
  GET_ONE_SETTINGS_PRODUCT_CATEGORIE,
  UPDATE_TELESTAT_FORM,
  GET_TELESTATE_FORM,
  GET_TRANSPORT,
  GET_SETTINGS_CASHIER,
  GET_SETTINGS_METOD_PAYMENTS,
  SET_SETTINGS_POLIGONES
} from "../constants/settings";

import {
  updadeLoadPageClients,
  updateModalDelete,
  updateLoadBtnResource,
  updateLoadEDITResource,
  updateLoadTableResource,
  updateLoadEditPayment,
  updateItemLoadByName
} from './view';

import {
  addNotification
} from './notifications';

import {
  messages
} from 'constants/notifications';

import { validDateGet } from 'utils/helpers/promocode';
import { uploadProgress } from 'utils/helpers/file';

import {
  n_plus_one,
  pickup_for_employees,
  late_courier_excuse,
} from "constants/promotions";
import { remomveDublicatesDeliveryPayments } from "utils/helpers/removeDublicates";

let startRange = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  }
];

export function getSettings() {
  return async (dispatch) => {
    const { data: cities } = await api.settings.getCities();
    const { data: kitchens } = await api.settings.getKitchens();
    const { data: locations } = await api.settings.getLocations();
    const { data: orderStatuses } = await api.settings.getOrderStatuses();
    const { data: orderTypes } = await api.settings.getOrderTypes();
    const { data: itemStatuses } = await api.settings.getItemsTypes();
    const { data: productCategories } = await api.settings.getProductCategories();
    const { data: productTypes } = await api.settings.getProductTypes();
    const { data: roles } = await api.settings.getRoles();
    const { data: restaurants } = await api.settings.getRestaurants();
    const { data: promotions } = await api.settings.getPromotion();
    const { data: resource } = await api.settings.getResource();
    const { data: payment } = await api.settings.getPayment();
    const { data: typesOfDelivery } = await api.settings.getTypesOfDelivery();
    const { data: reasonList } = await api.settings.getReasonsList();
    const { data: complaint } = await api.settings.getComplaintAll();
    const { data: sources } = await api.settings.getComplaintAllSources();
    const { data: cutlery } = await api.settings.getCutlerySetsAll();
    const { data: telestat } = await api.settings.getSwttingsTelestat();

    await dispatch({
      type: UPDATE_SETTINGS_DATA,
      payload: {
        cities,
        kitchens,
        locations,
        payment,
        orderStatuses,
        orderTypes,
        itemStatuses,
        productCategories,
        productTypes,
        roles,
        restaurants,
        promotions,
        resource,
        typesOfDelivery,
        reasonList,
        complaint,
        sources,
        cutlery,
        telestat,
      },
    });
  };
}

export const getAllCashier = () => {
  return async (dispatch) => {
    const { data } = await api.settings.getSettingAllCashier();

    await dispatch({
      type: GET_SETTINGS_CASHIER,
      payload: data,
    });
  }
}

export const getAllMetodPayments = () => {
  return async (dispatch) => {
    const data = await api.settings.getSettingsAllMetodPayment();

    await dispatch({
      type: GET_SETTINGS_METOD_PAYMENTS,
      payload: data,
    });
  }
}

export const getAllTransport = () => {
  return async (dispatch) => {
    const { data } = await api.settings.getCourierTransportTypesAll();

    await dispatch({
      type: GET_TRANSPORT,
      payload: data,
    });
  }
}

// telestat
export const updateFormTelestat = (item, data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TELESTAT_FORM,
      payload: { item, data },
    });
  }
}

export const saveTelestat = (setLoadBtn = () => { }) => {
  return async (dispatch, state) => {
    const {
      settings: {
        telestat
      }
    } = state();

    const objM = [
      {
        code: "link",
        value: telestat.link
      },
      {
        "code": "token",
        value: telestat.token
      },
      {
        code: "audio_link",
        value: telestat.audio_link
      }
    ];

    await setLoadBtn(true);

    const res = await api.settings.updateTelestat(objM);

    if (isArray(res?.data)) {
      await dispatch(addNotification({ suc: ["Оновлено"] }));
    } else {
      await dispatch(addNotification(res?.errors, 'error'));
    }

    await setLoadBtn(false);
  }
}

export const getFormTelestat = () => {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    const { data } = await api.settings.getSwttingsTelestat();

    await dispatch({
      type: GET_TELESTATE_FORM,
      payload: data,
    });

    await dispatch(updadeLoadPageClients(false));
  }
}
// end telestat

// ---- complaints
export const updateComplainPage = (status) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_COMPLAINT_LOAD_PAGE,
      payload: status,
    });
  }
}

export const updateComplainModal = (status) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_COMPLAINT_LOAD_MODAL,
      payload: status,
    });
  }
}

export const updateComplainForm = (item, data) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_COMPLAINT_FORM,
      payload: { item, data }
    });
  }
}

export const complainFormClean = () => {
  return async (dispatch) => {
    await dispatch({
      type: COMPLAINT_FORM_CLEAN,
    });
  }
}

export const complaintCrate = (setIsLoadBtn, closeModalCreate) => {
  return async (dispatch, state) => {
    const {
      settings: {
        complaints: {
          complaint: {
            form: {
              name,
              status
            }
          }
        }
      }
    } = state();

    try {
      await setIsLoadBtn(true);
      const res = await api.settings.complaintCrate({ name, status });

      if (res?.data?.id) {
        await dispatch(addNotification({ suc: ["Створено"] }));
        await dispatch(getComplaintAll());
      } else {
        await dispatch(addNotification(res.errors, 'error'));
      }

      await setIsLoadBtn(false);
      await closeModalCreate();
    } catch (e) {
      // error
    }
  }
}

export const updateComplaint = (setIsLoadBtn, closeModalCreate) => {
  return async (dispatch, state) => {
    const {
      settings: {
        complaints: {
          complaint: {
            form: {
              id,
              name,
              status
            }
          }
        }
      }
    } = state();

    try {
      await setIsLoadBtn(true);
      const res = await api.settings.updateComplaint(id, { name, status });

      if (res?.data?.id) {
        await dispatch(addNotification({ suc: ["Оновлено"] }));
        await dispatch(getComplaintAll());
      } else {
        await dispatch(addNotification(res.errors, 'error'));
      }

      await setIsLoadBtn(false);
      await closeModalCreate();
    } catch (e) {
      // error
    }
  }
}

export const getComplaintAll = () => {
  return async (dispatch, state) => {
    try {
      await dispatch(updateComplainPage(true));

      const res = await api.settings.getComplaintAll();

      dispatch({
        type: GET_COMPLAINT_ALL,
        payload: res.data
      });

      await dispatch(updateComplainPage(false));
    } catch (e) {
      // error
    }
  }
}

export const getComplaintOne = (id) => {
  return async (dispatch, state) => {
    try {
      await dispatch(updateComplainModal(true));
      const res = await api.settings.getComplaintOne(id);

      dispatch({
        type: GET_COMPLAINT_ONE,
        payload: res.data
      });

      await dispatch(updateComplainModal(false));
    } catch (e) {
      // error
    }
  }
}

export const deleteComplaint = (id, hanCloseModalDelete) => {
  return async (dispatch, state) => {
    try {
      await dispatch(updateComplainModal(true));
      const res = await api.settings.deleteComplaint(id);

      if (res == 1) {
        await dispatch(addNotification({ suc: ["Видалено"] }));
        await hanCloseModalDelete(false);
        await dispatch(getComplaintAll());
      } else {
        await dispatch(addNotification({ suc: ["Помилка"] }, 'error'));
      }

      await dispatch(updateComplainModal(false));
    } catch (e) {
      // error
    }
  }
}

// sources
export const updateComplainPageSources = (status) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_COMPLAINT_LOAD_PAGE_SOURCES,
      payload: status,
    });
  }
}

export const updateComplainModalSources = (status) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_COMPLAINT_LOAD_MODAL_SOURCES,
      payload: status,
    });
  }
}

export const updateComplainFormSources = (item, data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_COMPLAINT_FORM_SOURCES,
      payload: { item, data }
    });
  }
}

export const complainFormCleanSources = () => {
  return async (dispatch) => {
    dispatch({
      type: COMPLAINT_FORM_CLEAN_SOURCES,
    });
  }
}

export const complaintCrateSources = (setIsLoadBtn, closeModalCreate) => {
  return async (dispatch, state) => {
    const {
      settings: {
        complaints: {
          resource: {
            form: {
              name,
              status
            }
          }
        }
      }
    } = state();

    try {
      await setIsLoadBtn(true);
      const res = await api.settings.complaintCrateSources({ name, status });

      if (res?.data?.id) {
        await dispatch(addNotification({ suc: ["Створено"] }));
        await dispatch(getComplaintAllSources());
      } else {
        await dispatch(addNotification(res.errors, 'error'));
      }

      await setIsLoadBtn(false);
      await closeModalCreate();
    } catch (e) {
      // error
    }
  }
}

export const updateComplaintSources = (setIsLoadBtn, closeModalCreate) => {
  return async (dispatch, state) => {
    const {
      settings: {
        complaints: {
          resource: {
            form: {
              id,
              name,
              status
            }
          }
        }
      }
    } = state();

    try {
      await setIsLoadBtn(true);
      const res = await api.settings.updateComplaintSources(id, { name, status });

      if (res?.data?.id) {
        await dispatch(addNotification({ suc: ["Оновлено"] }));
        await dispatch(getComplaintAllSources());
      } else {
        await dispatch(addNotification(res.errors, 'error'));
      }

      await setIsLoadBtn(false);
      await closeModalCreate();
    } catch (e) {
      // error
    }
  }
}

export const getComplaintAllSources = () => {
  return async (dispatch, state) => {
    try {
      await dispatch(updateComplainPageSources(true));

      const res = await api.settings.getComplaintAllSources();

      dispatch({
        type: GET_COMPLAINT_ALL_SOURCES,
        payload: res.data
      });

      await dispatch(updateComplainPageSources(false));
    } catch (e) {
      // error
    }
  }
}

export const getComplaintOneSources = (id) => {
  return async (dispatch, state) => {
    try {
      await dispatch(updateComplainModalSources(true));
      const res = await api.settings.getComplaintOneSources(id);

      dispatch({
        type: GET_COMPLAINT_ONE_SOURCES,
        payload: res.data
      });

      await dispatch(updateComplainModalSources(false));
    } catch (e) {
      // error
    }
  }
}

export const deleteComplaintSources = (id, hanCloseModalDelete) => {
  return async (dispatch, state) => {
    try {
      await dispatch(updateComplainModalSources(true));
      const res = await api.settings.deleteComplaintSources(id);

      if (res == 1) {
        await dispatch(addNotification({ suc: ["Видалено"] }));
        await hanCloseModalDelete(false);
        await dispatch(getComplaintAllSources());
      } else {
        await dispatch(addNotification({ suc: ["Помилка"] }, 'error'));
      }

      await dispatch(updateComplainModalSources(false));
    } catch (e) {
      // error
    }
  }
}
// ---- end complaints

// ---- start bug reports
export const createBugReport = (bugReport) => {
  return async (dispatch) => {
    try {
      const res = await api.settings.createBugReport(bugReport);
      if (res.message) {
        await dispatch(addNotification({ suc: ["Баг-репорт відправлено! Незабаром виправимо :)"] }));
      } else if (res.error) {
        await dispatch(addNotification({ suc: [`Відбулася помилка: ${res.error}`] }, 'error'));
      }
    } catch (e) {
      // error
      console.error(e);

    }
  }
}

export const closeBugReport = (id) => {
  return async (dispatch) => {
    try {
      await dispatch(updateBugReportLoadModal(true));
      const res = await api.settings.closeBugReport(id);

      if (res.message) {
        await dispatch(addNotification({ suc: ["Баг закрито"] }));
      } else if (res.error) {
        await dispatch(addNotification({ suc: [`Помилка: ${res.error}`] }, 'error'));
      }

      await dispatch(updateBugReportLoadModal(false));
      return (res.message && !res.error);

    } catch (e) {
      // error
      console.error(e);
      return false;

    }
  }
}

export const updateBugReportLoadPage = (status) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_BUG_REPORT_LOAD_PAGE,
      payload: status,
    });
  }
}

export const updateBugReportLoadModal = (status) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_BUG_REPORT_LOAD_MODAL,
      payload: status,
    });
  }
}

export const selectBugReportToView = (bugReport) => {
  return async (dispatch) => {
    dispatch({
      type: SELECT_BUG_REPORT_TO_VIEW,
      payload: bugReport,
    });
  }
}

export const getBugReportAll = () => {
  return async (dispatch) => {
    try {
      await dispatch(updateBugReportLoadPage(true));

      const res = await api.settings.getBugReports();

      dispatch({
        type: GET_BUG_REPORT_ALL,
        payload: res.items
      });

      await dispatch(updateBugReportLoadPage(false));
    } catch (e) {
      // error
      console.error(e);

    }
  }
}

// ---- end bug reports

// reason
export const updateFormReason = (item, data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_REASON,
      payload: { item, data },
    });
  }
}

export const getReasonsList = () => {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const res = await api.settings.getReasonsList();

    await dispatch({
      type: GET_REASONS_LIST,
      payload: res.data,
    });

    await dispatch(updadeLoadPageClients(false));
  }
}

export const getReasonId = (id) => {
  return async (dispatch) => {
    await dispatch(updateItemLoadByName("isLoadGeneral", true));

    const res = await api.settings.getReasonId(id);

    await dispatch({
      type: GET_REASON_ID,
      payload: res.data,
    });

    await dispatch(updateItemLoadByName("isLoadGeneral", false));
  }
}

export const createReason = (setIsEdit = () => { }) => {
  return async (dispatch, state) => {
    let { settings: { blackList: { editFormReason: { title } } } } = state();

    await dispatch(updateItemLoadByName("isLoadGeneral", true));

    const res = await api.settings.createReason({ title });

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getReasonsList());
      await setIsEdit();
      await dispatch(cleaningNewFormObj({ obj: "blackList", name: "editFormReason" }));
      await dispatch(addNotification({ war: ['Готово'] }));
    }

    await dispatch(updateItemLoadByName("isLoadGeneral", false));
  };
}

export const updateReasonId = (setIsEdit = () => { }) => {
  return async (dispatch, state) => {
    let { settings: { blackList: { editFormReason: { id, title } } } } = state();

    await dispatch(updateItemLoadByName("isLoadGeneral", true));

    const res = await api.settings.updateReasonId(id, { title });

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getReasonsList());
      await setIsEdit();
      await dispatch(cleaningNewFormObj({ obj: "blackList", name: "editFormReason" }));
      await dispatch(addNotification({ war: ['Готово'] }));
    }

    await dispatch(updateItemLoadByName("isLoadGeneral", false));
  };
}

export const deleteItemReasonById = (id, setDeleteItem) => {
  return async (dispatch) => {
    await setDeleteItem(prev => ({
      ...prev,
      isLoad: true,
    }));

    const res = await api.settings.deleteItemReasonById(id);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else
      await setDeleteItem(prev => ({
        ...prev,
        isOpen: false,
        isLoad: false,
      }));
    await dispatch(addNotification(messages.doneDelete));
    await dispatch(getReasonsList());
  }
}

// end reason

// types of delivery 
export const updateFormDelivery = (item, data) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_TYPE_OF_DELIVERY,
      payload: { item, data },
    });
  }
}

export const getTypesOfDeliveryList = () => {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const res = await api.settings.getTypesOfDelivery();

    await dispatch({
      type: GET_TYPES_OF_DELIVERY_LIST,
      payload: res.data,
    });

    await dispatch(updadeLoadPageClients(false));
  }
}

export const getDeliveyId = (id) => {
  return async (dispatch) => {
    await dispatch(updateItemLoadByName("isLoadEditDelivery", true));

    const res = await api.settings.getDeliveryId(id);

    await dispatch({
      type: GET_DELIVERY_ID,
      payload: res.data,
    });

    await dispatch(updateItemLoadByName("isLoadEditDelivery", false));
  }
}

export const createDelivery = (setIsEdit = () => { }) => {
  return async (dispatch, state) => {
    let { settings: { editTypeOfDelivery } } = state();

    await dispatch(updateItemLoadByName("isLoadEditDelivery", true));

    let newObj = { ...editTypeOfDelivery };
    newObj.payments = remomveDublicatesDeliveryPayments(editTypeOfDelivery.payments);

    const res = await api.settings.createDelivery(newObj);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getTypesOfDeliveryList());
      await setIsEdit();
      await dispatch(cleaningNewForm("editTypeOfDelivery"));
    }

    await dispatch(updateItemLoadByName("isLoadEditDelivery", false));
  };
}

export const updateDeliveryId = (id, setIsEdit = () => { }) => {
  return async (dispatch, state) => {
    let { settings: { editTypeOfDelivery } } = state();

    let obj = { ...editTypeOfDelivery };

    await dispatch(updateItemLoadByName("isLoadEditDelivery", true));

    obj.payments = remomveDublicatesDeliveryPayments(obj.payments);
    obj = { ...obj, payments: obj.payments.map((el) => el.id) }

    const res = await api.settings.updateDeliveryId(id, obj);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getTypesOfDeliveryList());
      await setIsEdit();
      await dispatch(cleaningNewForm("editTypeOfDelivery"));
    }

    await dispatch(updateItemLoadByName("isLoadEditDelivery", false));
  };
}

export const deleteItemDeliverById = (id, setDeleteItem) => {
  return async (dispatch) => {
    await setDeleteItem(prev => ({
      ...prev,
      isLoad: true,
    }));

    const res = await api.settings.deleteItemDelivery(id);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else
      await setDeleteItem(prev => ({
        ...prev,
        isOpen: false,
        isLoad: false,
      }));
    await dispatch(addNotification(messages.doneDelete, 'done'));
    await dispatch(getTypesOfDeliveryList());
  }
}

// end types of deliver

// ---- pickup
export function getFormPickup() {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const res = await api.settings.getPickup();

    await dispatch({
      type: GET_FORM_PICKUP,
      payload: res.data,
    });

    await dispatch(updadeLoadPageClients(false));
  }
}

export function saveFormPickup() {
  return async (dispatch, state) => {
    const {
      settings: {
        pickupForm
      }
    } = state();

    dispatch(updadeLoadPageClients(true));

    const res = await api.settings.updatePickup(pickupForm);

    if (res.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: GET_FORM_PICKUP,
        payload: res.data,
      });
    }
    await dispatch(updadeLoadPageClients(false));
  }
}

export function updateFormPickup(item, data) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_PICKUP,
      payload: { item, data },
    });
  }
}

// ---- payment
export function getPayment() {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const res = await api.settings.getPayment();

    await dispatch({
      type: GET_PAYMENT_LIST,
      payload: res.data,
    });

    await dispatch(updadeLoadPageClients(false));
  }
}

export function getPaymentId(id) {
  return async (dispatch) => {
    await dispatch(updateLoadEditPayment(true));

    const res = await api.settings.getPaymentId(id);

    await dispatch({
      type: GET_PAYMENT_ID,
      payload: res.data,
    });

    await dispatch(updateLoadEditPayment(false));
  }
}

export function updateFormPayment(item, data) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PAYMENT_FORM,
      payload: { item, data },
    });
  }
}

export function createPayment(setIsEdit = () => { }) {
  return async (dispatch, state) => {
    let { settings: { paymentEditForm } } = state();

    await dispatch(updateLoadEditPayment(true));

    const res = await api.settings.createPayment(paymentEditForm);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getPayment());
      await setIsEdit();
      await dispatch(cleaningNewForm("paymentEditForm"));
    }

    await dispatch(updateLoadEditPayment());
  };
}

export function updatePaymentId(id, setIsEdit = () => { }) {
  return async (dispatch, state) => {
    let { settings: { paymentEditForm } } = state();

    await dispatch(updateLoadEditPayment(true));

    const res = await api.settings.updatePaymentId(id, paymentEditForm);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getPayment());
      await setIsEdit();
      await dispatch(cleaningNewForm("paymentEditForm"));
    }

    await dispatch(updateLoadEditPayment());
  };
}

// ---- end payment

export function getResource() {
  return async (dispatch) => {
    await dispatch(updateLoadTableResource(true));

    const { data } = await api.settings.getResource();

    if (data) {
      await dispatch({ type: GET_RESOURCE_LIST, payload: data });
    }

    await dispatch(updateLoadTableResource(false));
  };
}

export function getResourceId(id) {
  return async (dispatch) => {
    const { data } = await api.settings.getResourceId(id);

    await dispatch(updateLoadEDITResource(true));

    if (data) {
      await dispatch({ type: GET_RESOURCE_ID, payload: data });
    }

    setTimeout(async () => {
      await dispatch(updateLoadEDITResource(false));
    }, 200);
  };
}

export function createResource(setIsEdit = () => { }) {
  return async (dispatch, state) => {
    let { settings: { resourceEdit } } = state();

    await dispatch(updateLoadBtnResource(true));

    const res = await api.settings.createResource(resourceEdit);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getResource());
      await setIsEdit(false);
      await dispatch(cleaningNewForm("resourceEdit"));
    }

    await dispatch(updateLoadBtnResource(false));
  };
}

export function updateResource(setIsEdit = () => { }) {
  return async (dispatch, state) => {
    let { settings: { resourceEdit } } = state();

    await dispatch(updateLoadBtnResource(true));

    const res = await api.settings.updateResource(resourceEdit.id, resourceEdit);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getResource());
      await setIsEdit(false);
      await dispatch(cleaningNewForm("resourceEdit"));
    }

    await dispatch(updateLoadBtnResource(false));
  };
}

export function deleteResource(id) {
  return async (dispatch) => {
    await dispatch(updateModalDelete('loadingsModal', true));

    const res = await api.settings.deleteResource(id);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else

      await dispatch(updateModalDelete('isOpen', false));
    await dispatch(updateModalDelete('loadingsModal', false));
    await dispatch(addNotification(messages.doneDelete, 'done'));
    await dispatch(getResource());
  }
}

// ---- cities ----

export function getSettingsCities() {
  return async (dispatch) => {
    const { data: cities } = await api.settings.getCities();
    await dispatch({ type: UPDATE_SETTINGS_CITIES_LIST, payload: cities });
  };
}

export function updateFormCity(item, data) {
  return {
    type: UPDATE_CITY_FORM,
    payload: { item, data },
  };
}

export function getCitySyncId(syncId) {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const { data } = await api.settings.getCitySyncId(syncId);

    await dispatch({
      type: GET_CITY,
      payload: data,
    });

    dispatch(updadeLoadPageClients(false));
  };
}

export function createCity(createCityCallback = () => { }) {
  return async (dispatch, state) => {
    let { settings: { editCity } } = state();

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.createCity(editCity);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: GET_CITY,
        payload: res.data
      });
    }

    await dispatch(updadeLoadPageClients(false));

    await createCityCallback(res.data?.sync_id);
  };
}

export function updateCity(onCloseModalDowload) {
  return async (dispatch, state) => {
    let { settings: { editCity } } = state();

    await dispatch(updadeLoadPageClients(true));

    onCloseModalDowload();

    const res = await api.settings.updateCity(editCity.id, editCity);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: GET_CITY,
        payload: res.data
      });
    }

    await dispatch(updadeLoadPageClients(false));
  };
}

export function deleteCityStreetById(id, closeModal, onLoadModal) {
  return async (dispatch) => {
    await (onLoadModal());
    await dispatch({
      type: DELETE_CITY_BY_ID,
      payload: id,
    });
    await (closeModal());
  }
}

export function updateCityStreet(id, value, updateLoadModalIn, onCloseModalInput) {
  return async (dispatch) => {
    await updateLoadModalIn(true);
    await dispatch({
      type: UPDATE_CITY_STREET_BY_ID,
      payload: { id, value },
    });
    await updateLoadModalIn(false);
    await onCloseModalInput();
  }
}

export function createStreetByCity(textStreet, updateLoadModalIn, onCloseModalInput) {
  return async (dispatch) => {
    await updateLoadModalIn(true);
    await dispatch({
      type: ADD_STREET_BY_CITY,
      payload: textStreet,
    });
    await updateLoadModalIn(false);
    await onCloseModalInput();
  }
}

export function uploadFileStreet(file, setAddFileStreet = () => { }) {
  return async (dispatch, state) => {
    const { settings: {
      editCity: {
        id
      }
    } } = state();
    const formData = new FormData();
    formData.append("file", file);

    setAddFileStreet(prev => (
      {
        ...prev,
        isResnonse: true,
      }
    ));

    try {
      const signedUrlResp = await Axios.post(`${API_URL}/${API_PREFIX}/cities/${id}/save-streets-file`,
        formData, {
        headers: {
          Authorization: "Bearer " + api.apiClient.getToken(),
          "Content-type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          let precentage = uploadProgress(progressEvent);

          setAddFileStreet(prev => (
            {
              ...prev,
              process: precentage,
            }
          ));
        }
      });

      const { data, status, errors } = await signedUrlResp;

      if (errors) {
        await dispatch(addNotification(errors, 'error'));
        await setAddFileStreet(prev => ({
          ...prev,
          process: 0,
          isResnonse: false,
          isFile: false,
          fileName: '',
        }));
      }

      if (status === 200) {
        setAddFileStreet((prev) => {
          return {
            ...prev,
            isResnonse: false,
            isFile: true,
            fileName: data.data.file_name,
          }
        });
      }
    } catch (error) {
      dispatch(addNotification(error.response.data.errors, 'error'));
      await setAddFileStreet(prev => ({
        ...prev,
        process: 0,
        isResnonse: false,
        isFile: false,
        fileName: '',
      }));
    }
  }
}

export function getSettingsKitchens(params = {}) {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const { data: kitchens } = await api.settings.getKitchens(params);
    await dispatch({ type: UPDATE_SETTINGS_KITCHENS_LIST, payload: kitchens });

    dispatch(updadeLoadPageClients(false));
  };
}

export function getSettingsRestaurants(params = {}) {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const { data: restaurants } = await api.settings.getRestaurants(params);

    await dispatch({ type: UPDATE_SETTINGS_RESTAURANS_LIST, payload: restaurants });

    dispatch(updadeLoadPageClients(false));
  };
}

export function getSettingsLocations() {
  return async (dispatch) => {
    dispatch(updadeLoadPageClients(true));

    const { data: locations } = await api.settings.getLocations();

    await dispatch({
      type: UPDATE_SETTINGS_LOCATIONS_LIST,
      payload: locations,
    });

    dispatch(updadeLoadPageClients(false));
  };
}

export function getLocationId(id) {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    const { data } = await api.settings.getLocationId(id);

    await dispatch({
      type: GET_LOCATION,
      payload: data,
    });

    await dispatch(updadeLoadPageClients(false));
  };
}

export function updateFormLocation(item, data) {
  return {
    type: UPDATE_LOCATION_FORM,
    payload: { item, data },
  };
}

export function createLocation(createLocationCallback = () => { }) {
  return async (dispatch, state) => {
    let { settings: { editLocation } } = state();

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.createLocation(editLocation);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ suc: ["Додано"] }));
      await dispatch({
        type: GET_LOCATION,
        payload: res.data
      });
    }

    await dispatch(updadeLoadPageClients(false));

    await createLocationCallback(res.data?.id);
  };
}

export function updateLocation() {
  return async (dispatch, state) => {
    let { settings: { editLocation } } = state();

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.updateLocation(editLocation.id, editLocation);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ suc: ["Оновлено"] }));
      await dispatch({
        type: GET_LOCATION,
        payload: res.data
      });
    }

    await dispatch(updadeLoadPageClients(false));
  };
}

export function getSettingsOrderStatuses() {
  return async (dispatch) => {
    const { data: orderStatuses } = await api.settings.getOrderStatuses();
    await dispatch({
      type: UPDATE_SETTINGS_ORDER_STATUSES,
      payload: orderStatuses,
    });
  };
}

export function getSettingsOrderTypes() {
  return async (dispatch) => {
    const { data: orderTypes } = await api.settings.getOrderTypes();
    await dispatch({ type: UPDATE_SETTINGS_ORDER_TYPES, payload: orderTypes });
  };
}

export function getSettingsItemsTypes() {
  return async (dispatch) => {
    const { data: itemStatuses } = await api.settings.getItemsTypes();
    await dispatch({
      type: UPDATE_SETTINGS_ITEMS_STATUSES,
      payload: itemStatuses,
    });
  };
}

export function updateFormCategories(item, data) {
  return {
    type: UPDATE_SETTINGS_PRODUCT_CATEGORIES_FORM,
    payload: { item, data },
  };
}

export function updateCategorieOfid(setState, closeModal, dataObj = null, setSettingCat = () => { }) {
  return async (dispatch, state) => {
    const {
      settings: {
        productCategoriesForm
      }
    } = state();

    let dat = !!dataObj?.id ? dataObj : productCategoriesForm;

    await setState(true);
    const res = await api.settings.updatesettingsProductCategories(dat.id, dat);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else if (res?.data) {
      await dispatch(addNotification({ suc: ["Оновлено"] }));
      if (!!dataObj?.id) {
        dispatch(getSettingsProductCategories({ restaurant: dataObj.restaurant }));
        setSettingCat({ 
          ...res?.data, 
          cutlery_sets: (res?.data.cutlery_sets || []).map(item => item.id) 
        });
      }
    }
    await closeModal();
  };
}

export function oneSettingsProductCategories(id, setState) {
  return async (dispatch) => {
    await setState(true);
    const { data } = await api.settings.oneSettingsProductCategories(id);
    await dispatch({
      type: GET_ONE_SETTINGS_PRODUCT_CATEGORIE,
      payload: data,
    });
    await setState(false);
  };
}

export function getSettingsProductCategories(params = {}, setIsLoadPage = () => { }) {
  return async (dispatch) => {
    await setIsLoadPage(true);

    delete params.page; // Це видалити коли зроблять бек

    const { data: productCategories } = await api.settings.getProductCategories(params);

    await dispatch({
      type: UPDATE_SETTINGS_PRODUCT_CATEGORIES,
      payload: productCategories,
    });

    await setIsLoadPage(false);
  };
}

export function getSettingsProductTypes() {
  return async (dispatch) => {
    const { data: productTypes } = await api.settings.getProductTypes();
    await dispatch({
      type: UPDATE_SETTINGS_PRODUCT_TYPES,
      payload: productTypes,
    });
  };
}

export function getSettingsRoles() {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    const { data: roles } = await api.settings.getRoles();

    await dispatch({ type: UPDATE_SETTINGS_ROLES_LIST, payload: roles });

    await dispatch(updadeLoadPageClients(false));
  };
}

export function getRoleId(id) {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    const { data } = await api.settings.getRoleId(id);

    if (isObject(data)) {
      await dispatch({
        type: GET_ROLE,
        payload: data
      });
    }

    await dispatch(updadeLoadPageClients(false));
  };
}

export function updataRole() {
  return async (dispatch, state) => {
    let { settings: { editRole } } = state();

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.updataRole(editRole.id, { title: editRole.title, pages: editRole.pages });

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else if (res?.data) {
      await dispatch({
        type: GET_ROLE,
        payload: res.data
      });
      await dispatch(addNotification(messages.doneEdit, 'done'));
    }

    await dispatch(updadeLoadPageClients(false));
  }
}

export function deleteRole(id) {
  return async (dispatch) => {
    await dispatch(updateModalDelete('loadingsModal', true));

    const res = await api.settings.deleteRole(id);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    }

    await dispatch(updateModalDelete('isOpen', false));
    await dispatch(updateModalDelete('loadingsModal', false));
    await dispatch(addNotification(messages.doneDelete, 'done'));
    await dispatch(getSettingsRoles());
  }
}

export function createRole(createRoleCallback = () => { }) {
  return async (dispatch, state) => {
    let { settings: { editRole } } = state();

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.createRole({ title: editRole.title, pages: editRole.pages });

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else if (res?.data) {
      await dispatch({
        type: GET_ROLE,
        payload: res.data
      });
      await dispatch(addNotification(messages.doneCreate, 'done'));
    }

    await dispatch(updadeLoadPageClients(false));

    await createRoleCallback();
  }
}

export function updateFormRole(item, data) {
  return {
    type: UPDATE_ROLE_FORM,
    payload: { item, data },
  };
}

export function updateFormRolePages(item, data) {
  return {
    type: UPDATE_ROLE_FORM_PAGES,
    payload: { item, data },
  };
}

export const getActiveKitchensByCity = () =>
  api.settings.getActiveKitchensByCity();

export const updateKitchenForm = (item, data) => {
  return {
    type: UPDATE_KITCHEN_FORM,
    payload: { item, data },
  };
}

export const updateKitchenFormShiftFirst = (item, data) => {
  return {
    type: UPDATE_KITCHEN_FORM_SHIFT_FIRST,
    payload: { item, data },
  };
}

export const updateKitchenFormShiftLast = (item, data) => {
  return {
    type: UPDATE_KITCHEN_FORM_SHIFT_LAST,
    payload: { item, data },
  };
}

export function createKitchen(createKitchenCallback = () => { }) {
  return async (dispatch, state) => {
    let { settings: { editKitchens } } = state();

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.createKitchen(editKitchens);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ successfull: ["Додано"] }));
      await createKitchenCallback(res.data?.code);

      await dispatch({
        type: SET_KITCHEN,
        payload: { data: res.data, updateKitchenStore: true }
      });
    }

    await dispatch(updadeLoadPageClients(false));
  };
}

export function updateKitchen() {
  return async (dispatch, state) => {
    let { settings: { editKitchens } } = state();

    const deliveryTime = editKitchens.delivery_time;
    let editKitchen = {
      title: editKitchens.title,
      phone: editKitchens.phone,
      address: editKitchens.address,
      city_sync_id: editKitchens.city_sync_id,
      latitude: editKitchens.latitude,
      longitude: editKitchens.longitude,
      time_open: editKitchens.time_open,
      time_close: editKitchens.time_close,
      closed_until: editKitchens.closed_until,
      status: editKitchens.status,
      telegram_bot_name: editKitchens.telegram_bot_name,
      telegram_bot_token: editKitchens.telegram_bot_token,
      pickup: editKitchens.pickup,
      shifts: editKitchens.shifts,
      create_cash_checks_to: editKitchens.create_cash_checks_to,
      create_cashless_checks_to: editKitchens.create_cashless_checks_to,
      initiated_by_user: editKitchens.initiatedByUser,
      delivery_time: (deliveryTime && Object.keys(deliveryTime).length) ? deliveryTime : null,
    };

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.updateKitchen(editKitchens.id, editKitchen);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ successfull: ["Оновлено"] }));
      await dispatch({
        type: SET_KITCHEN,
        payload: { data: res.data, updateKitchenStore: true }
      });
    }

    await dispatch(updadeLoadPageClients(false));
  };
}

export function getKitchen(code) {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    const { data } = await api.settings.getKitchen(code);

    await dispatch({
      type: SET_KITCHEN,
      payload: { data }
    });

    await dispatch(updadeLoadPageClients(false));
  };
}

export const cleaningNewFormObj = (obj) => {
  return {
    type: CLEANING_NEW_FORM_OBJ,
    payload: obj,
  };
}

export const cleaningNewForm = (item) => {
  return {
    type: CLEANING_NEW_FORM,
    payload: { item }
  };
}

export function updateFormRestaurant(item, data) {
  return {
    type: UPDATE_RESTAURANT_FORM,
    payload: { item, data },
  };
}

export function getRestorant(code) {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    const { data } = await api.settings.getRestorant(code);

    await dispatch({
      type: SET_RESTORANT,
      payload: data
    });

    await dispatch(updadeLoadPageClients(false));
  };
}

export function uploadImage(fileImg, callback = () => { }) {
  return async (dispatch) => {
    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.uploadImage(fileImg);
    if (res?.success) {
      callback(res);
    } else if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    }

    await dispatch(updadeLoadPageClients(false));
    return res;
  }
}

export function updateRestorant(fileImg = undefined) {
  return async (dispatch, state) => {
    let { settings: { editRestaurant } } = state();
    let dataRestaurant = {
      name: editRestaurant.name,
      order_review_sms_text: editRestaurant.order_review_sms_text,
      cart_notify_message: editRestaurant.cart_notify_message,
      cart_notify_btn_name: editRestaurant.cart_notify_btn_name,
      cart_notify_btn_link: editRestaurant.cart_notify_btn_link,
      cart_notify_delay: editRestaurant.cart_notify_delay,
      cart_notify_image: editRestaurant.cart_notify_image,
      status: editRestaurant.status,
      sale_employee_pickup: editRestaurant.sale_employee_pickup,
      sale_employee_delivery: editRestaurant.sale_employee_delivery,
    };

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.updateRestorant(editRestaurant.id, dataRestaurant);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: SET_RESTORANT,
        payload: res.data
      });
    }

    await dispatch(updadeLoadPageClients(false));
    dispatch(addNotification({ war: ['Готово'] }));

    if (fileImg) {
      await updateRestorantImage(res.data.id, fileImg);
    }
  };
}

export function createRestorant(createRestaurantCallback = () => { }, fileImg = undefined) {
  return async (dispatch, state) => {
    let { settings: { editRestaurant } } = state();
    let dataRestaurant = {
      name: editRestaurant.name,
      order_review_sms_text: editRestaurant.order_review_sms_text,
      cart_notify_message: editRestaurant.cart_notify_message,
      cart_notify_btn_name: editRestaurant.cart_notify_btn_name,
      cart_notify_btn_link: editRestaurant.cart_notify_btn_link,
      cart_notify_delay: editRestaurant.cart_notify_delay,
      cart_notify_image: editRestaurant.cart_notify_image,
      status: editRestaurant.status,
      sale_employee_pickup: editRestaurant.sale_employee_pickup,
      sale_employee_delivery: editRestaurant.sale_employee_delivery,
    };

    await dispatch(updadeLoadPageClients(true));

    const res = await api.settings.createRestorant(dataRestaurant);

    console.log(res)

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: SET_RESTORANT,
        payload: res.data
      });
    }

    await dispatch(updadeLoadPageClients(false));
    dispatch(addNotification({ war: ['Готово'] }));

    if (fileImg) {
      await updateRestorantImage(res.data.id, fileImg);
    }

    await createRestaurantCallback(res.data?.code);
  };
}

async function updateRestorantImage(id, image) {
  api.settings.updateRestorantImage(image, id, "formData");
}

export function getPromotion() {
  return async (dispatch) => {
    await dispatch(updateFormPromotionWindow('isLoad', true));

    const { data } = await api.settings.getPromotion();

    await dispatch({
      type: GET_PROMOTION,
      payload: data
    });

    await dispatch(updateFormPromotionWindow('isLoad', false));
  };
}

export function setCreatePromotionDefaultValues() {
  return async (dispatch, state) => {
    await dispatch(updateFormPromotionWindow('isLoadEdit', true));

    const { settings: { restaurants } } = state();
    const res = restaurants.reduce((accumulator, current) => ({
      ...accumulator,
      [current.code]: {
        status: true,
        bonus_time: 30,
        remind_days: 3,
        free_products: [],
        sms_notify_text: "",
        sms_courier_late_text: "",
      }
    }), {});

    await dispatch({
      type: GET_PROMOTIO_ITEM,
      payload: { restaurants: res }
    });

    await dispatch(updateFormPromotionWindow('isLoadEdit', false));
  };
}

export function getPromotionId(id) {
  return async (dispatch) => {
    await dispatch(updateFormPromotionWindow('isLoadEdit', true));

    const { data } = await api.settings.getPromotionId(id);

    let dataKonvert = {
      ...data
    }

    if (data.type === late_courier_excuse && Array.isArray(data.restaurants)) {
      dataKonvert.restaurants = data.restaurants.reduce((accumulator, { restaurant_code, created_at, updated_at, ...args }) => ({
        ...accumulator,
        [restaurant_code]: args,
      }), {});
    }

    if (data.time_frame_type === 'days_of_the_week') {
      dataKonvert.days_of_the_week = data.time_frame;
      dataKonvert.period = startRange;
    } else {
      dataKonvert.period = data.time_frame;
      dataKonvert.days_of_the_week = [];
    }

    await dispatch({
      type: GET_PROMOTIO_ITEM,
      payload: dataKonvert
    });

    await dispatch(updateFormPromotionWindow('isLoadEdit', false));
  };
}

export function createPromotion() {
  return async (dispatch, state) => {
    const { settings: {
      promotionsEdit: {
        data
      }
    } } = state();

    await dispatch(updateFormPromotionWindow('isLoadEdit', true));

    let dataCreate = {
      type: data.type,
      name: data.name,
      description: data.description,
      city_id: data.city_id,
      status: data.status,
      isActions: data.isActions,
    };

    if (data.type === late_courier_excuse) {
      dataCreate = {
        ...dataCreate,
        restaurants: data.restaurants,
      }
    }

    if (data.type === n_plus_one) {
      dataCreate = {
        ...dataCreate,
        product_count: data.product_count,
        product_category_id: data.product_category_id,
        time_frame_type: data.time_frame_type,
      }

      if (data.time_frame_type === 'days_of_the_week') {
        dataCreate.time_frame = data.days_of_the_week;
      } else {
        dataCreate.time_frame = data.period;
      }
    } else if (data.type === pickup_for_employees) {
      dataCreate = {
        ...dataCreate,
        sale: data.sale,
        roles: data.roles,
        product_categories: data.product_categories,
      }
    }

    const res = await api.settings.createPromotion(dataCreate);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getPromotion());
      await dispatch(updateFormPromotionWindow('isOpen', false));
    }
    await dispatch(updateFormPromotionWindow('isLoadEdit', false));
  };
}

export function updatePromotion() {
  return async (dispatch, state) => {
    const { settings: {
      promotionsEdit: {
        data
      }
    } } = state();

    let dataCreate = {
      type: data.type,
      name: data.name,
      description: data.description,
      city_id: data.city_id,
      status: data.status,
      isActions: data.isActions,
    };

    if (data.type === late_courier_excuse) {
      dataCreate = {
        ...dataCreate,
        restaurants: data.restaurants,
      }
    }

    if (data.type === n_plus_one) {
      dataCreate = {
        ...dataCreate,
        product_count: data.product_count,
        product_category_id: data.product_category_id,
        time_frame_type: data.time_frame_type,
      }

      if (data.time_frame_type === 'days_of_the_week') {
        dataCreate.time_frame = data.days_of_the_week;
      } else {
        dataCreate.time_frame = data.period;
      }
    } else if (data.type === pickup_for_employees) {
      dataCreate = {
        ...dataCreate,
        sale: data.sale,
        roles: data.roles,
        product_categories: data.product_categories,
      }
    }

    await dispatch(updateFormPromotionWindow('isLoadEdit', true));

    const res = await api.settings.updatePromotion(data.id, dataCreate);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      let dataKonvert = {
        ...res.data
      }

      if (data.type === n_plus_one) {
        if (res.data.time_frame_type === 'days_of_the_week') {
          dataKonvert.days_of_the_week = res.data.time_frame;
          dataKonvert.period = startRange;
        } else {
          dataKonvert.period = res.data.time_frame;
          dataKonvert.days_of_the_week = [];
        }
      } else if (data.type === late_courier_excuse && Array.isArray(res.data.restaurants)) {
        dataKonvert.restaurants = res.data.restaurants.reduce((accumulator, { restaurant_code, created_at, updated_at, ...args }) => ({
          ...accumulator,
          [restaurant_code]: args,
        }), {});
      }

      await dispatch({
        type: GET_PROMOTIO_ITEM,
        payload: dataKonvert
      });
      await dispatch(updateFormPromotionWindow('isOpen', false));
      await dispatch(getPromotion());
    }

    await dispatch(updateFormPromotionWindow('isLoadEdit', false));
  };
}

export function deletePromotion(id) {
  return async (dispatch) => {
    await dispatch(updateModalDelete('loadingsModal', true));

    const res = await api.settings.deletePromotion(id);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    }

    await dispatch(updateModalDelete('isOpen', false));
    await dispatch(getPromotion());
    await dispatch(updateModalDelete('loadingsModal', false));
  };
}

export function updateFormPromotion(item, data) {
  return {
    type: UPDATE_FORM_PROMOTION,
    payload: { item, data },
  };
}

export function updateFormPromotionWindow(item, data, isNew = false) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_PROMOTION_WINDOW,
      payload: { item, data, isNew },
    });
  }
}

export function updateFormPromotionWeek(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_PROMOTION_WEEK,
      payload: value,
    });
  }
}

// ---- promocode ----

export function getPromocode() {
  return async (dispatch) => {
    await dispatch(updateFormPromocodeWindow('isLoad', true));

    const { data } = await api.settings.getPromocode();

    await dispatch({
      type: GET_PROMOCODE,
      payload: data
    });

    await dispatch(updateFormPromocodeWindow('isLoad', false));
  };
}

export function getPromocodeId(id) {
  return async (dispatch) => {
    await dispatch(updateFormPromocodeWindow('isLoadEdit', true));

    const { data } = await api.settings.getPromocodeId(id);

    if (data) {
      let dataNew = data;

      dataNew.period = validDateGet(data.work_start, data.work_end);

      await dispatch({
        type: GET_PROMOCODE_ITEM,
        payload: dataNew
      });
    }

    await dispatch(updateFormPromocodeWindow('isLoadEdit', false));
  };
}

export function createPromocode() {
  return async (dispatch, state) => {
    const { settings: {
      promocodesEdit: {
        data
      }
    } } = state();

    let validData = {
      ...data,
      work_start: data.period[0].startDate,
      work_end: data.period[0].endDate,
    };

    await dispatch(updateFormPromocodeWindow('isLoadEdit', true));

    const res = await api.settings.createPromocode(validData);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(getPromocode());
      await dispatch(updateFormPromocodeWindow('isOpen', false));
    }
    await dispatch(updateFormPromocodeWindow('isLoadEdit', false));
  };
}

export function updatePromocode() {
  return async (dispatch, state) => {
    const { settings: {
      promocodesEdit: {
        data
      }
    } } = state();

    let validData = {
      ...data,
      work_start: data.period[0].startDate,
      work_end: data.period[0].endDate,
    };

    await dispatch(updateFormPromocodeWindow('isLoadEdit', true));

    const res = await api.settings.updatePromocode(data.id, validData);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      let resValid = res.data;

      resValid.period = validDateGet(resValid.work_start, resValid.work_end);

      await dispatch({
        type: GET_PROMOCODE_ITEM,
        payload: resValid,
      });
      await dispatch(updateFormPromocodeWindow('isOpen', false));
      await dispatch(getPromocode());
    }

    await dispatch(updateFormPromocodeWindow('isLoadEdit', false));
  };
}

export function deletePromocode(id) {
  return async (dispatch) => {
    await dispatch(updateModalDelete('loadingsModal', true));

    const res = await api.settings.deletePromocode(id);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    }

    await dispatch(updateModalDelete('isOpen', false));
    await dispatch(getPromocode());
    await dispatch(updateModalDelete('loadingsModal', false));
  };
}

export function updateFormPromocode(item, data) {
  return {
    type: UPDATE_FORM_PROMOCODE,
    payload: { item, data },
  };
}

export function updateFormPromocodeArray(item, data) {
  let dataNew = [];

  data.map((el) => {
    dataNew.push(el.value);
  });

  return {
    type: UPDATE_FORM_PROMOCODE_ARRAY,
    payload: { item, data: dataNew },
  };
}

export function updateFormPromocodeWindow(item, data, isNew = false) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_PROMOCODE_WINDOW,
      payload: { item, data, isNew },
    });
  }
}

export function deleteOneFieldPromocode(data) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ONE_FIELD_PROMOCODE,
      payload: { data },
    });
  }
}

export function addOneFieldPromocode(item, data) {
  return async (dispatch) => {
    dispatch({
      type: ADD_ONE_FIELD_PROMOCODE,
      payload: { item, data },
    });
  }
}

export function updateFormPromocodeItemCode(index, value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ONE_FIELD_CODE_PROMOCODE,
      payload: { index, value },
    });
  }
}

export function addOneFieldCodePromocode() {
  return async (dispatch) => {
    dispatch({
      type: ADD_ONE_FIELD_CODE_PROMOCODE,
    });
  }
}

export function deleteOneFieldCodePromocode(index) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ONE_FIELD_CODE_PROMOCODE,
      payload: { index },
    });
  }
}

export function updateFormResource(item, data) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_FORM_RESOURCE,
      payload: { item, data },
    });
  }
}


export function setPoligones(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_SETTINGS_POLIGONES,
      payload: data,
    });
  }
}
