import * as React from "react";
import moment from "moment";

// lodash
import { cloneDeep } from "lodash";

// styled
import styled from "styled-components";

// scss
import classes from "./ModalKitchenDeliveryTime.module.scss";

// helpers
import { withLoading } from "utils/helpers/withLoading";

// components
import { MenuItem } from "components/menuPageMulti/menuItem";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { TimeInput } from "components/ui-kit/inputs/TimeInput";
import Checkbox from "components/ui-kit/Checkbox";
import ButtonIcon from "components/ui-kit/ButtonIcon";

// save icon
import SaveIcon from "@mui/icons-material/Save";

// main food categories
import { MAIN_CATEGORY_TYPE_LIST } from "constants/schemes";

const MenuItemList = styled.ul`
    margin-top: 10px;
    display: flex;
`;

export const ModalContent = ({
  // kitchenCode,  
  editKitchens,
  restaurants,
  closeModal,
  updateKitchen,
  updateKitchenForm,
  initiatedByUser = false,
}) => {
  const [restaurant, setRestaurant] = React.useState(restaurants[0]?.code);
  const [isLoad, setIsLoad] = React.useState(false);

  React.useLayoutEffect(() => {
    updateKitchenForm("initiatedByUser", initiatedByUser);
  }, [initiatedByUser]);

  const restaurantDeliveryTime = React.useMemo(() => {
    // console.log(editKitchens.delivery_time?.[restaurant] || {});
    return editKitchens.delivery_time?.[restaurant] || {};
  }, [editKitchens, restaurant]);

  const onUpdateKitchen = async () => {
    await withLoading(setIsLoad, updateKitchen);
    closeModal();
  }

  const toggleDeliveryOption = React.useCallback((option, status) => {
    // Activates or disabled delivery options for restaurant:
    // Either pickup or delivery.

    const copy = cloneDeep(editKitchens.delivery_time || {});
    copy[restaurant] = copy[restaurant] || {};

    if (status) {
      copy[restaurant][option] = MAIN_CATEGORY_TYPE_LIST.reduce((accumulator, current) => {
        return {
          ...accumulator,
          [current.value]: '',
        } // Fill with data, e.g.: {pizza: '', sushi: '', other: ''}
      }, {});

    } else {
      delete copy[restaurant]?.[option];
      if (!Object.keys(copy[restaurant]).length) {
        delete copy[restaurant];
      }
    }

    updateKitchenForm("delivery_time", copy);

  }, [restaurant, editKitchens, updateKitchenForm]);

  const updateDeliveryTimeModel = React.useCallback((option, field, value) => {
    const copy = cloneDeep(editKitchens.delivery_time || {});
    copy[restaurant][option][field] = value;

    updateKitchenForm("delivery_time", copy);

  }, [restaurant, editKitchens, updateKitchenForm]);

  return (
    <div className="cont-modal-info">
      <MenuItemList>
        {restaurants.map(({ code, name }) => (
          <MenuItem
            key={code}
            inKey={code}
            title={name}
            onHandle={setRestaurant}
            isActive={restaurant === code}
          />
        ))}
      </MenuItemList>

      <div className={classes.modalBody}>
        <div className={classes.goodsReceivementType}>
          <Checkbox
            title="Доставка"
            isStyle
            onChecked={toggleDeliveryOption.bind(this, "delivery", !restaurantDeliveryTime.delivery)}
            checked={!!restaurantDeliveryTime.delivery}
          />
          <Checkbox
            title="Самовивіз"
            isStyle
            onChecked={toggleDeliveryOption.bind(this, "pickup", !restaurantDeliveryTime.pickup)}
            checked={!!restaurantDeliveryTime.pickup}
          />
        </div>

        {/* Доставка */}
        <fieldset className={classes.fieldset} disabled={!restaurantDeliveryTime.delivery}>
          <legend>Час доставки</legend>
          {MAIN_CATEGORY_TYPE_LIST.map((category) => (
            <TextInput
              key={`delivery-${category.value}`}
              title={category.label}
              type="number"
              max={100}
              min={0}
              width="100%"
              value={restaurantDeliveryTime.delivery?.[category.value]}
              isStyle={true}
              onChange={(e) => updateDeliveryTimeModel("delivery", category.value, e.target.value)}
            />
          ))}
        </fieldset>

        {/* Самовивіз */}
        <fieldset className={classes.fieldset} disabled={!restaurantDeliveryTime.pickup}>
          <legend>Час самовивозу</legend>
          {MAIN_CATEGORY_TYPE_LIST.map((category) => (
            <TextInput
              key={`pickup-${category.value}`}
              title={category.label}
              type="number"
              max={100}
              min={0}
              width="100%"
              value={restaurantDeliveryTime.pickup?.[category.value]}
              isStyle={true}
              onChange={(e) => updateDeliveryTimeModel("pickup", category.value, e.target.value)}
            />
          ))}
        </fieldset>

        <div className="head-modal-f">
          <div className="head-modal-f__title" style={{ marginTop: "5px", marginBottom: "5px" }}>Стоп кухні</div>
          <Checkbox
            title="Не приймати замовлення"
            isStyle
            onChecked={updateKitchenForm.bind(
              this,
              "closed_until",
              editKitchens.closed_until ?
                null : moment().format("YYYY-MM-DD HH:mm:ss"),
            )}
            checked={!!editKitchens.closed_until}
          />
        </div>

        {/* Стоп кухні: не приймати замовлення до */}
        <fieldset className={classes.fieldset} disabled={!editKitchens.closed_until}>
          <legend>Не приймати до</legend>
          <TimeInput
            width="100%"
            value={editKitchens.closed_until && moment(editKitchens.closed_until)}
            isStyle={true}
            onChange={(time) => updateKitchenForm("closed_until", time.format("YYYY-MM-DD HH:mm:ss"))}
          />
        </fieldset>

        <div className={classes.btnWrapper}>
          <ButtonIcon
            title="Зберегти"
            onClick={onUpdateKitchen}
            icon={<SaveIcon />}
            isLoad={isLoad}
            positionIcon="left"
          />
        </div>

      </div>
    </div>
  );
};
